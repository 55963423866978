import { cva, cx, type VariantProps } from 'class-variance-authority'
import { forwardRef } from 'react'

import type { buttonSize } from './Button'
import { button } from './Button'
import type { IconVariant } from './ui-components/Icon'
import Icon from './ui-components/Icon'

const iconClasses = cva('', {
  variants: {
    iconSize: {
      xs: 'h-3 w-3',
      sm: 'h-5 w-5',
      md: 'h-6 w-6',
      lg: 'h-8 w-8'
    }
  },
  defaultVariants: {
    iconSize: 'sm'
  }
})

const iconButtonSize = cva('rounded-full', {
  variants: {
    size: {
      sm: 'p-1',
      md: 'p-3',
      lg: 'p-4'
    }
  },
  defaultVariants: {
    size: 'md'
  }
})

export const iconButtonStyles = ({
  className,
  color,
  variant,
  size
}: { className?: string } & VariantProps<typeof button> & VariantProps<typeof buttonSize>) =>
  cx(button({ color, variant, class: className }), iconButtonSize({ size }))

type Intrinsic = Omit<JSX.IntrinsicElements['button'], 'aria-label' | 'color'>

export interface IconButtonProps
  extends Intrinsic,
    VariantProps<typeof button>,
    VariantProps<typeof buttonSize>,
    VariantProps<typeof iconClasses> {
  ariaLabel: string
  icon: IconVariant
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ ariaLabel, className, color, icon, iconSize, size, variant, ...props }, ref) => (
    <button ref={ref} aria-label={ariaLabel} className={iconButtonStyles({ color, variant, className, size })} {...props}>
      <Icon className={iconClasses({ iconSize })} variant={icon} />
    </button>
  )
)

IconButton.displayName = 'IconButton'
